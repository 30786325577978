import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { ConsumerProfileStore } from '../../../../../../../modules/consumer/modules/consumer-profile/store/consumer-profile.store'
import { uatId } from '../../../../../../../uat/uat-id.function'
import { CommonStyles } from '../../../../../../../ui-library/styles/common.styles'
import { toBoolean } from '../../../../../../helpers/to-boolean/to-boolean-formatter'
import { DisclaimerMessages } from './disclaimer.messages'
import { DisclaimerProps } from './disclaimer.props'
import { DisclaimerStyles } from './disclaimer.styles'
import { disclaimerUat } from './disclaimer.uat'
import { getLinkForLocale } from './types/disclaimer.functions'

export const Disclaimer = (props: DisclaimerProps) => {
  const vendor = useSelector(ConsumerProfileStore.getVendor)
  const LearnMoreLink = (linkText: string) => (
    <a
      {...uatId(disclaimerUat.link)}
      className={CommonStyles.anchorLink}
      target="_blank"
      rel="noreferrer"
      href={getLinkForLocale(props.locale)}
    >
      {linkText}
    </a>
  )

  const disclaimerMessage = useMemo(() => {
    return toBoolean(vendor?.rules.showCustomCreditCheckNoticeMessage) ? DisclaimerMessages.creditCheckNoticeSegway : DisclaimerMessages.creditCheckNoticeDefault
  }, [vendor])

  return (
    <div className={DisclaimerStyles.container(props.alignCenter)}>
      <p>
        <FormattedMessage {...disclaimerMessage} />
      </p>
      <p>
        <FormattedMessage
          {...DisclaimerMessages.learnMore}
          values={{
            a: LearnMoreLink
          }}
        />
      </p>
    </div>
  )
}
