import clsx from 'clsx'
import { useMemo } from 'react'
import { ChevronLeftIcon } from '../../../shared/icons/chevron-left/chevron-left.icon'
import { uatId } from '../../../uat/uat-id.function'
import { Paragraph } from '../paragraph/paragraph.component'
import { PrimaryHeaderProps } from './primary-header.props'
import { PrimaryHeaderStyles } from './primary-header.styles'
import { PrimaryHeaderUat } from './primary-header.uat'

export const PrimaryHeader = (props: PrimaryHeaderProps) => {
  const goBack = useMemo(
    () => (props.goBack && props.showBackIcon ? props.goBack : undefined),
    [props.goBack]
  )

  return (
    <div className={PrimaryHeaderStyles.container(props.horizontalPadding || false)}>
      <div className={PrimaryHeaderStyles.iconContainer} onClick={goBack}>
        <ChevronLeftIcon
          className={PrimaryHeaderStyles.primaryHeaderBackIcon}
          showIf={props.showBackIcon}
          uat={uatId(PrimaryHeaderUat.backButton)}
        />
        <Paragraph className={PrimaryHeaderStyles.backButtonText}>
          {props.backButtonText}
        </Paragraph>
      </div>
      <h1
        className={clsx(PrimaryHeaderStyles.header, props.className)}
        {...props.uat}
      >
        {props.children}
      </h1>
    </div>
  )
}
