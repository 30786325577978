export const PreQualifierAssetTypeSelectionViewMessages = {
  title: {
    id: 'Pages.PreQualifierAssetTypeSelection.Page.title',
    description: 'Select asset-type page title'
  },
  description: {
    id: 'Pages.PreQualifierAssetTypeSelection.Page.description',
    description: 'Select asset-type page description'
  },
  descriptionAlternative: {
    id: 'Pages.PreQualifierAssetTypeSelection.Page.descriptionAlternative',
    description: 'Select asset-type page description'
  },
  submitButton: {
    id: 'Pages.PreQualifierAssetTypeSelection.Page.submitButton',
    description: 'Submit'
  }
}
