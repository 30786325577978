import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { RichFormattedMessage } from '../../../../../../../../shared/components/rich-formatted-message/rich-formatted-message.component'
import { formatCurrency } from '../../../../../../../../shared/helpers/currency/currency-formatter'
import { Language } from '../../../../../../../../shared/types/language.enum'
import { uatId } from '../../../../../../../../uat/uat-id.function'
import { Modal } from '../../../../../../../../ui-library/components/modal/modal.component'
import { Paragraph } from '../../../../../../../../ui-library/components/paragraph/paragraph.component'
import { PrimaryButton } from '../../../../../../../../ui-library/components/primary-button/primary-button.component'
import { SettingsStore } from '../../../../../../../app/modules/settings/store/settings.store'
import { PreQualifierAssetTypeDetailsDialogMessages } from './pre-qualifier-asset-type-details-dialog.messages'
import { PreQualifierAssetTypeDetailsDialogProps } from './pre-qualifier-asset-type-details-dialog.props'
import { PreQualifierAssetTypeDetailsDialogStyles } from './pre-qualifier-asset-type-details-dialog.styles'
import { PreQualifierAssetTypeDetailsDialogUat } from './pre-qualifier-asset-type-details-dialog.uat'

export const PreQualifierAssetTypeDetailsDialog = (props: PreQualifierAssetTypeDetailsDialogProps) => {
  const locale = useSelector(SettingsStore.getLocaleSetting)

  const onClose = () => {
    props.onClose(false)
  }

  const onSelect = () => {
    props.onClose(true)
  }

  const formatPreQualifyAmount = () => {
    if (props.assetType) {
      return formatCurrency(locale, props.assetType.maximumPreQualifyAmount)
    }
    return null
  }

  return (
    <Modal
      open={props.isOpen}
      className={PreQualifierAssetTypeDetailsDialogStyles.dialog}
    >
      <div className={PreQualifierAssetTypeDetailsDialogStyles.container}>
        <div
          className={PreQualifierAssetTypeDetailsDialogStyles.imageContainer}
        >
          <img
            className={PreQualifierAssetTypeDetailsDialogStyles.image}
            src={props.assetType?.imageUrl}
            alt={props.assetType?.name}
          />
        </div>
        <div className={PreQualifierAssetTypeDetailsDialogStyles.right}>
          <h3
            className={PreQualifierAssetTypeDetailsDialogStyles.header}
            {...uatId(PreQualifierAssetTypeDetailsDialogUat.title)}
          >
            {props.assetType?.name}
          </h3>
          <Paragraph uat={uatId(PreQualifierAssetTypeDetailsDialogUat.title)}>
            {props.assetType?.description}
          </Paragraph>
          <div
            className={
              PreQualifierAssetTypeDetailsDialogStyles.ratesContainer
            }
          >
            <div
              className={PreQualifierAssetTypeDetailsDialogStyles.ratesItem}
            >
              <div
                className={
                  PreQualifierAssetTypeDetailsDialogStyles.ratesTitle
                }
              >
                <FormattedMessage
                  {...PreQualifierAssetTypeDetailsDialogMessages.competitiveRateTitle}
                />
              </div>
              <div
                className={
                  PreQualifierAssetTypeDetailsDialogStyles.ratesValue
                }
              >
                <RichFormattedMessage
                  message={props.assetType?.competitiveRateRange}
                />
              </div>
            </div>
            <div
              className={PreQualifierAssetTypeDetailsDialogStyles.ratesItem}
            >
              <div
                className={
                  PreQualifierAssetTypeDetailsDialogStyles.ratesTitle
                }
              >
                <FormattedMessage
                  {...PreQualifierAssetTypeDetailsDialogMessages.termsTitle}
                />
              </div>
              <div
                className={
                  PreQualifierAssetTypeDetailsDialogStyles.ratesValue
                }
              >
                <RichFormattedMessage message={props.assetType?.termsRange} />
              </div>
            </div>
            <div
              className={PreQualifierAssetTypeDetailsDialogStyles.ratesItem}
            >
              <div
                className={
                  PreQualifierAssetTypeDetailsDialogStyles.ratesTitle
                }
              >
                <FormattedMessage
                  {...PreQualifierAssetTypeDetailsDialogMessages.preQualifyAmountTitle}
                />
              </div>
              <div
                className={
                  PreQualifierAssetTypeDetailsDialogStyles.ratesValue
                }
              >
                <FormattedMessage
                  {...PreQualifierAssetTypeDetailsDialogMessages.upToLabel}
                  values={{ upTo: formatPreQualifyAmount() }}
                />
              </div>
            </div>
          </div>
          <Paragraph
            className={PreQualifierAssetTypeDetailsDialogStyles.disclaimer}
          >
            <FormattedMessage
              {...PreQualifierAssetTypeDetailsDialogMessages.disclaimer}
            />
          </Paragraph>
        </div>
      </div>
      <div
        className={PreQualifierAssetTypeDetailsDialogStyles.buttonsContainer}
      >
        <button
          className={PreQualifierAssetTypeDetailsDialogStyles.closeButton}
          onClick={onClose}
          {...uatId(PreQualifierAssetTypeDetailsDialogUat.cancelButton)}
        >
          <FormattedMessage
            {...PreQualifierAssetTypeDetailsDialogMessages.closeButtonLabel}
          />
        </button>
        <div
          className={
            locale === Language.French ?
              PreQualifierAssetTypeDetailsDialogStyles.selectButtonContainerFrench :
              PreQualifierAssetTypeDetailsDialogStyles.selectButtonContainer
          }
        >
          <PrimaryButton
            onClick={onSelect}
            uat={uatId(PreQualifierAssetTypeDetailsDialogUat.confirmButton)}
            minimumWidth
          >
            <FormattedMessage
              {...PreQualifierAssetTypeDetailsDialogMessages.selectButtonLabel}
            />
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  )
}
