export const DisclaimerMessages = {
  main: {
    id: 'MainLayout.Footer.Disclaimer.main',
    description: 'LendCare is ...'
  },
  creditCheckNoticeDefault: {
    id: 'MainLayout.Footer.Disclaimer.creditCheckNoticeDefault',
    description: 'soft-credit check...'
  },
  creditCheckNoticeSegway: {
    id: 'MainLayout.Footer.Disclaimer.creditCheckNoticeSegway',
    description: 'soft-credit check...'
  },
  learnMore: {
    id: 'MainLayout.Footer.Disclaimer.learnMore',
    description: 'Learn more about'
  }
}
