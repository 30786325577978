import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { uatId } from '../../../../../../../../uat/uat-id.function'
import { PrimaryHeader } from '../../../../../../../../ui-library/components/primary-header/primary-header.component'
import { ConsumerProfileStore } from '../../../../../consumer-profile/store/consumer-profile.store'
import { ConsumerProfileState } from '../../../../../consumer-profile/types/consumer-profile-state.interface'
import { ConsumerNetIncomeStepStore } from '../../store/consumer-net-income-step.store'
import { ConsumerNetIncomeStepState } from '../../types/consumer-net-income-step-state.interface'
import { ConsumerNetIncomeForm } from '../consumer-net-income-form/consumer-net-income-form.components'
import { ConsumerNetIncomeViewMessages } from './consumer-net-income-view.messages'
import { ConsumerNetIncomeViewProps } from './consumer-net-income-view.props'
import { ConsumerNetIncomeViewUat } from './consumer-net-income-view.uat'
import { ConsumerProfileAssetImageContainer } from '../../../../../../../../shared/components/consumer-profile-asset-image-container/consumer-profile-asset-image-container.component'
import { toBoolean } from '../../../../../../../../shared/helpers/to-boolean/to-boolean-formatter'

export const ConsumerNetIncomeView = (props: ConsumerNetIncomeViewProps) => {
  const intl = useIntl()
  const firstName = useSelector(ConsumerProfileStore.getConsumerFirstName)
  const isWorkflowStepComplete = useSelector(
    ConsumerNetIncomeStepStore.getIsWorkflowStepComplete
  )

  const loading: ConsumerNetIncomeStepState['loading'] = useSelector(
    ConsumerNetIncomeStepStore.getLoading
  )

  const [netMonthlyIncome, setNetMonthlyIncome] = useState<number | null>(
    useSelector(ConsumerProfileStore.getConsumerNetMonthlyIncome)
  )

  useEffect(() => {
    if (isWorkflowStepComplete) {
      ConsumerProfileStore.setConsumerNetIncome(netMonthlyIncome)
      props.onComplete()
    }
  }, [netMonthlyIncome, isWorkflowStepComplete, props])

  const onSubmit = (monthlyIncome: ConsumerProfileState['netIncome']): void => {
    setNetMonthlyIncome(monthlyIncome)
    ConsumerNetIncomeStepStore.saveConsumerNetIncome(monthlyIncome)
  }

  useEffect(() => {
    return () => {
      ConsumerNetIncomeStepStore.reset()
    }
  }, [])

  return (
    <>
      <PrimaryHeader
        showBackIcon={toBoolean(props.step.rules.showBackButton)}
        goBack={props.goBack}
      />

      <ConsumerProfileAssetImageContainer
        titleMessage={intl.formatMessage(ConsumerNetIncomeViewMessages.title, {firstName})}
        titleUat={uatId(ConsumerNetIncomeViewUat.title)}
        subTitleMessage={intl.formatMessage(ConsumerNetIncomeViewMessages.subTitle)}
        moreInfoMessage={intl.formatMessage(ConsumerNetIncomeViewMessages.moreInfoDescription)}
        noHorizontalPadding
        showFooter
      >
        <ConsumerNetIncomeForm
          initialFormValue={netMonthlyIncome}
          onSubmit={onSubmit}
          loading={loading}
          submitError={useSelector(ConsumerNetIncomeStepStore.getError)}
        />
      </ConsumerProfileAssetImageContainer>
    </>
  )
}
