import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { uatId } from '../../../../../../../../uat/uat-id.function'
import { PrimaryHeader } from '../../../../../../../../ui-library/components/primary-header/primary-header.component'
import { withHideIf } from '../../../../../../../../ui-library/helpers/with-hide-if/with-hide-if.component'
import { ConsumerProfileStore } from '../../../../../consumer-profile/store/consumer-profile.store'
import { ConsumerEmailStepStore } from '../../store/consumer-email-step.store'
import { ConsumerEmailStepState } from '../../types/consumer-email-step-state.interface'
import { ConsumerEmailForm } from '../consumer-email-form/consumer-email-form.component'
import { ConsumerEmailViewMessages } from './consumer-email-view.messages'
import { ConsumerEmailViewProps } from './consumer-email-view.props'
import { ConsumerEmailViewUat } from './consumer-email-view.uat'
import { ConsumerProfileAssetImageContainer } from '../../../../../../../../shared/components/consumer-profile-asset-image-container/consumer-profile-asset-image-container.component'
import { toBoolean } from '../../../../../../../../shared/helpers/to-boolean/to-boolean-formatter'

export const ConsumerEmailView = withHideIf((props: ConsumerEmailViewProps) => {
  const intl = useIntl()
  const email = useSelector(ConsumerEmailStepStore.getEmail)
  const isWorkflowStepComplete = useSelector(
    ConsumerEmailStepStore.getIsWorkflowStepComplete
  )

  const loading: ConsumerEmailStepState['loading'] = useSelector(
    ConsumerEmailStepStore.getLoading
  )

  useEffect(() => {
    return () => {
      ConsumerEmailStepStore.reset()
    }
  }, [])

  useEffect(() => {
    if (isWorkflowStepComplete) {
      ConsumerProfileStore.setConsumerEmail(email)
      props.onComplete()
    }
  }, [email, isWorkflowStepComplete, props])

  return (
    <>
      <PrimaryHeader
        showBackIcon={toBoolean(props.step.rules.showBackButton)}
        goBack={props.goBack}
      />

      <ConsumerProfileAssetImageContainer
        titleMessage={intl.formatMessage(ConsumerEmailViewMessages.sendCopy)}
        titleUat={uatId(ConsumerEmailViewUat.sendCopy)}
        subTitleMessage={intl.formatMessage(ConsumerEmailViewMessages.askEmail)}
        subTitleUat={uatId(ConsumerEmailViewUat.askEmail)}
        noHorizontalPadding
        showFooter
      >
        <ConsumerEmailForm
          initialFormValue={useSelector(ConsumerProfileStore.getConsumerEmail)}
          onSubmit={ConsumerEmailStepStore.saveConsumerEmail}
          loading={loading}
        />
      </ConsumerProfileAssetImageContainer>
    </>
  )
})
