import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ConsumerProfileAssetImageContainer } from '../../../../../../../../shared/components/consumer-profile-asset-image-container/consumer-profile-asset-image-container.component'
import { toBoolean } from '../../../../../../../../shared/helpers/to-boolean/to-boolean-formatter'
import { PrimaryHeader } from '../../../../../../../../ui-library/components/primary-header/primary-header.component'
import { ConsumerProfileStore } from '../../../../../consumer-profile/store/consumer-profile.store'
import { ConsumerProfileAssetOverview } from '../consumer-profile-asset-overview/consumer-profile-asset-overview.component'
import { ConsumerProductViewMessages } from './consumer-review-asset-view.messages'
import { ConsumerReviewAssetViewProps } from './consumer-review-asset-view.props'

export const ConsumerReviewAssetView = (
  props: ConsumerReviewAssetViewProps
) => {
  const vendor = useSelector(ConsumerProfileStore.getVendor)
  const intl = useIntl();

  const descriptionMessage = useMemo(() => {
    return toBoolean(vendor?.rules.showCustomCreditCheckNoticeMessage) ? ConsumerProductViewMessages.descriptionAlternative : ConsumerProductViewMessages.description
  }, [vendor])

  return (
    <>
      <PrimaryHeader
        showBackIcon={toBoolean(props.step.rules.showBackButton)}
        goBack={props.goBack}
      />
      <ConsumerProfileAssetImageContainer
        titleMessage={intl.formatMessage(ConsumerProductViewMessages.header)}
        subTitleMessage={intl.formatMessage(descriptionMessage)}
        showFooter
        noHorizontalPadding
      >
        <ConsumerProfileAssetOverview
          onComplete={props.onComplete}
          productDetails={props.productDetails}
        />
      </ConsumerProfileAssetImageContainer>
    </>
  )
}
