export const ConsumerProfileAssetSummaryViewStyles = {
  container: 'flex flex-col gap-y-4 pb-5 w-full content-center',
  vendorContainer: 'mx-auto flex flex-row items-center mb-1',
  vendorImage: 'inline-block w-16',
  assetTypeDescription: 'font-bold text-20px ml-5',
  productContainer: 'flex mx-auto items-center sm:flex-row flex-col',
  assetTypeImage: 'w-52 h-auto inline-block',
  msrpContainer: 'flex flex-col flex-grow gap-y-3 sm:pl-12 mt-1',
  msrpValueContainer: 'pl-6 font-bold text-24px',
  productImage: 'w-64 bg-slate'
}
