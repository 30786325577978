export const ProductDetailsStyles = {
    container: 'flex justify-center gap-x-2 flex-col items-center w-full sm:max-w-1xl',
    productDetailsContainer: 'flex flex-row items-center gap-x-2 sm:gap-x-4 mt-1',
    productDisplayContainer: 'flex flex-col items-center justify-center w-full sm:max-w-1xl',
    brandLogoContainer: 'h-12 flex items-center sm:h-16 mb-1',
    brandLogo: 'h-full w-auto object-contain mx-auto',
    productName: 'text-lg text-left text-goEasyBlue400 font-montserrat font-semibold sm:text-2xl',
    image: 'object-contain',
    msrpContainer: 'flex flex-row my-4 items-center sm:items-start sm:flex-col',
    msrpTitle: 'text-14px',
    msrpValue: 'font-montserrat font-semibold text-goEasyBlue400 text-24px ml-3 sm:ml-6',
}