import { useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { PreQualifierAssetType } from '../../../../../../domain/pre-qualifier-asset-type/pre-qualifier-asset-type.interface'
import { toBoolean } from '../../../../../../shared/helpers/to-boolean/to-boolean-formatter'
import { uatId } from '../../../../../../uat/uat-id.function'
import { Paragraph } from '../../../../../../ui-library/components/paragraph/paragraph.component'
import { PrimaryButton } from '../../../../../../ui-library/components/primary-button/primary-button.component'
import { PrimaryHeader } from '../../../../../../ui-library/components/primary-header/primary-header.component'
import { withShowIf } from '../../../../../../ui-library/helpers/with-show-if/with-show-if.component'
import { VendorPathParams } from '../../../../types/vendor-path-params.interface'
import { ConsumerProfileStore } from '../../../consumer-profile/store/consumer-profile.store'
import { ConsumerWorkflowStepsStore } from '../../../consumer-workflow/modules/consumer-workflow-steps/store/consumer-workflow-steps.store'
import { setVendorIdToLocalStorage } from '../../../pre-qualifier-loading-product/types/pre-qualifier-product-local-storage.functions'
import { PreQualifierAssetTypeSelectionStore } from '../../store/pre-qualifier-asset-type-selection.store'
import { PreQualifierAssetTypeSelectionState } from '../../types/pre-qualifier-asset-type-selection-state.interface'
import { PreQualifierAssetTypeDetailsDialog } from '../pre-qualifier-asset-type-list/components/pre-qualifier-asset-type-details-dialog/pre-qualifier-asset-type-details-dialog.component'
import { PreQualifierAssetTypeList } from '../pre-qualifier-asset-type-list/pre-qualifier-asset-type-list.component'
import { PreQualifierAssetTypeSelectionViewMessages } from './pre-qualifier-asset-type-selection-view.messages'
import { PreQualifierAssetTypeSelectionViewProps } from './pre-qualifier-asset-type-selection-view.props'
import { PreQualifierAssetTypeSelectionViewStyles } from './pre-qualifier-asset-type-selection-view.styles'
import { PreQualifierAssetTypeSelectionViewUat } from './pre-qualifier-asset-type-selection-view.uat'

export const PreQualifierAssetTypeSelectionView = withShowIf(
  (props: PreQualifierAssetTypeSelectionViewProps) => {
    const vendor = useSelector(ConsumerProfileStore.getVendor)
    const [selectedAssetType, setSelectedAssetType] = useState<
      PreQualifierAssetTypeSelectionState['selectedAssetType']
    >(useSelector(PreQualifierAssetTypeSelectionStore.getSelectedAssetType))
    const [assetTypeDetailsOpen, setAssetTypeDetailsOpen] =
      useState<boolean>(false)
    const params = useParams<VendorPathParams>()

    useEffect(() => {
      if (params.vendorId) {
        ConsumerProfileStore.setVendorId(params.vendorId)
        setVendorIdToLocalStorage(params.vendorId)
      }
    }, [params])

    const selectAssetType = (assetType: PreQualifierAssetType) => {
      setSelectedAssetType(assetType)
      setAssetTypeDetailsOpen(true)
    }

    const closeAssetTypeDetailsDialog = (keepSelection: boolean) => {
      setAssetTypeDetailsOpen(false)
      if (!keepSelection) {
        setSelectedAssetType(null)
      }
    }

    const query = new URLSearchParams(window.location.search)
    const assetTypeAlias = query.get('asset-type')
    const autoStartAlias = query.get('autostart')

    useEffect(() => {
      props.assetTypes.forEach((assetType) => {
        if (assetType.alias === assetTypeAlias) {
          setSelectedAssetType(assetType)
          if (autoStartAlias === 'true') {
            onSubmit(assetType)
          }
        }
      })
    }, [assetTypeAlias, autoStartAlias])

    const onSubmit = (assetType?: PreQualifierAssetType) => {
      const value = selectedAssetType ?? assetType
      if (value) {
        PreQualifierAssetTypeSelectionStore.setSelectedAssetType(value)
        ConsumerWorkflowStepsStore.setWorkflowSteps(value.workflow.steps)
        ConsumerWorkflowStepsStore.setActiveWorkflowStep(
          value.workflow.steps[0]
        )
        ConsumerProfileStore.setConsumerAssetType(value.assetTypeId)
        ConsumerProfileStore.setBannerUrl(value.imageUrl)
      }
    }

    const descriptionMessage = useMemo(() => {
        return toBoolean(vendor?.rules.showCustomCreditCheckNoticeMessage) ? PreQualifierAssetTypeSelectionViewMessages.descriptionAlternative : PreQualifierAssetTypeSelectionViewMessages.description
      }, [vendor])

    return (
      <>
        <PrimaryHeader uat={uatId(PreQualifierAssetTypeSelectionViewUat.title)}>
          <FormattedMessage
            {...PreQualifierAssetTypeSelectionViewMessages.title}
          />
        </PrimaryHeader>
        <Paragraph
          className={PreQualifierAssetTypeSelectionViewStyles.paragraph}
          uat={uatId(PreQualifierAssetTypeSelectionViewUat.description)}
        >
          <FormattedMessage
            {...descriptionMessage}
          />
        </Paragraph>
        <PreQualifierAssetTypeList
          assetTypes={props.assetTypes}
          selectedAssetType={selectedAssetType}
          selectAssetType={selectAssetType}
        />
        <div
          className={PreQualifierAssetTypeSelectionViewStyles.bottomContainer}
        >
          <div
            className={PreQualifierAssetTypeSelectionViewStyles.submitButton}
          >
            <PrimaryButton
              cssContainerClasses={PreQualifierAssetTypeSelectionViewStyles.submitButtonContainer}
              disabled={!Boolean(selectedAssetType?.id)}
              onClick={onSubmit}
              uat={uatId(PreQualifierAssetTypeSelectionViewUat.selectButton)}
            >
              <FormattedMessage
                {...PreQualifierAssetTypeSelectionViewMessages.submitButton}
              />
            </PrimaryButton>
          </div>
        </div>
        <PreQualifierAssetTypeDetailsDialog
          isOpen={assetTypeDetailsOpen}
          assetType={selectedAssetType}
          onClose={closeAssetTypeDetailsDialog}
        />
      </>
    )
  }
)
