import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { ConsumerName } from '../../../../../../../../domain/consumer/consumer-profile/consumer-name.interface'
import { ConsumerProfileAssetImageContainer } from '../../../../../../../../shared/components/consumer-profile-asset-image-container/consumer-profile-asset-image-container.component'
import { toBoolean } from '../../../../../../../../shared/helpers/to-boolean/to-boolean-formatter'
import { uatId } from '../../../../../../../../uat/uat-id.function'
import { PrimaryHeader } from '../../../../../../../../ui-library/components/primary-header/primary-header.component'
import { withHideIf } from '../../../../../../../../ui-library/helpers/with-hide-if/with-hide-if.component'
import { ConsumerProfileStore } from '../../../../../consumer-profile/store/consumer-profile.store'
import { ConsumerNameForm } from '../consumer-name-form/consumer-name-form.component'
import { ConsumerNameViewMessages } from './consumer-name-view.messages'
import { ConsumerNameViewProps } from './consumer-name-view.props'
import { ConsumerNameViewUat } from './consumer-name-view.uat'

export const ConsumerNameView = withHideIf((props: ConsumerNameViewProps) => {
  const intl = useIntl();
  const vendor = useSelector(ConsumerProfileStore.getVendor)
  const onSubmit = (consumerName: ConsumerName): void => {
    ConsumerProfileStore.setConsumerName(consumerName)
    props.onComplete()
  }

  const paragraphMessage = useMemo(() => {
      return toBoolean(vendor?.rules.showCustomCreditCheckNoticeMessage) ? ConsumerNameViewMessages.paragraphAlternative : ConsumerNameViewMessages.paragraph
    }, [vendor])

  return (
    <>
      <PrimaryHeader
        showBackIcon={toBoolean(props.step.rules.showBackButton)}
        goBack={props.goBack}
      />

      <ConsumerProfileAssetImageContainer
        titleMessage={intl.formatMessage(paragraphMessage)}
        titleUat={uatId(ConsumerNameViewUat.header)}
        subTitleMessage={intl.formatMessage(ConsumerNameViewMessages.formTitle)}
        subTitleUat={uatId(ConsumerNameViewUat.paragraph)}
        noHorizontalPadding
        showFooter
      >
          <ConsumerNameForm
            initialFormValues={useSelector(ConsumerProfileStore.getConsumerName)}
            onSubmit={onSubmit}
          />
      </ConsumerProfileAssetImageContainer>
    </>
  )
})
