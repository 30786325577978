export const ConsumerProductViewMessages = {
  header: {
    id: 'Pages.ConsumerProduct.View.header',
    description: 'Consumer product view header'
  },
  description: {
    id: 'Pages.ConsumerProduct.View.description',
    description: 'Consumer product view description'
  },
  descriptionAlternative: {
    id: 'Pages.ConsumerProduct.View.descriptionAlternative',
    description: 'Consumer product view description'
  },
}
